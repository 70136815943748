/**
 * 会员管理API
 */

import request from '@/utils/request'
import md5 from 'js-md5'

/**
 * 获取会员列表
 * @param params
 */
export function getMemberList(params) {
  return request({
    url: 'seller/members',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取员工会员列表
 * @param params
 */
export function getSellerMember(params) {
  return request({
    url: 'seller/members/listSellerMember',
    method: 'get',
    loading: false,
    params
  })
}


/**
 * 添加会员
 * @param params
 */
export function addMember(params) {
  params = JSON.parse(JSON.stringify(params))
  params.password = md5(params.password)
  return request({
    url: 'seller/members',
    method: 'post',
    data: params
  })
}

/**
 * 添加店铺员工
 * @param params
 */
export function addSellerMember(params) {
  params = JSON.parse(JSON.stringify(params))
  // params.password = md5(params.password)
  return request({
    url: '/seller/members/sellerMember',
    method: 'post',
    data: params
  })
}

/**
 * 修改店铺员工
 * @param id
 * @param params
 */
export function editSellerMember(id, params) {
  return request({
    url: `seller/members/sellerMember/${id}`,
    method: 'put',
    data: params
  })
}

//充值审核
export function sellerMmbersAuditChargeMoney(data) {
  return request({
    url: '/seller/members/auditChargeMoney',
    method: 'put',
    data
  })
}

/**
 * 批量导入会员积分信息
 * @param params
 */
export function importSellerMember(params) {
  // params = JSON.parse(JSON.stringify(params))
  return request({
    url: '/seller/members/import',
    headers: {'Content-Type': 'application/json'},
    method: 'post',
    data: params
  })
}

/**
 * 获取导出积分列表
 * @param params
 */
export function getExportMembers(params) {
  return request({
    url: '/seller/members/export',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取积分导入批次
 * @param params
 */
export function getQueryBatchList(params) {
  return request({
    url: '/seller/members/num/queryBatchList',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取积分导入批次详情
 * @param params
 */
export function getQueryImportList(params) {
  return request({
    url: '/seller/members/num/queryImportList',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 转移积分
 * @param params
 */
export function transferConsumPoint(params) {
  return request({
    url: '/seller/members/num/transferConsumPoint',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取会员详情
 * @param id
 */
export function getMemberDetail(id) {
  return request({
    url: `seller/members/${id}`,
    method: 'get'
  })
}

/**
 * 修改会员
 * @param id
 * @param params
 */
export function editMember(id, params) {
  if (params.password) {
    params.password = md5(params.password)
  }
  return request({
    url: `seller/members/${id}`,
    method: 'put',
    data: params
  })
}

/**
 * 删除会员
 * @param id
 */
export function deleteMember(id) {
  return request({
    url: `seller/members/${id}`,
    method: 'delete'
  })
}

/**
 * 获取会员回收站列表
 * @param params
 */
export function getRecycleMemberList(params) {
  return request({
    url: 'seller/members',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 恢复会员
 * @param id
 */
export function recycleMember(id) {
  return request({
    url: `seller/members/${id}`,
    method: 'post'
  })
}

/**
 * 获取会员收货地址列表
 * @param member_id
 * @param params
 */
export function getMemberAddress(member_id, params) {
  return request({
    url: `seller/members/addresses/${member_id}`,
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取会员咨询列表
 * @param params
 */
export function getMemberAsks(params) {
  return request({
    url: 'seller/members/asks',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 删除会员咨询
 * @param ask_id
 */
export function deleteMemberAsk(ask_id) {
  return request({
    url: `seller/members/asks/${ask_id}`,
    method: 'delete'
  })
}

/**
 * 获取会员评论列表
 * @param params
 */
export function getMemberComments(params) {
  return request({
    url: 'seller/members/comments',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 删除会员评论
 * @param comment_id
 */
export function deleteMemberComments(comment_id) {
  return request({
    url: `seller/members/comments/${comment_id}`,
    method: 'delete'
  })
}

/**
 * 修改会员消费积分
 * @param member_id
 * @param point
 */
export function editMemberConsumPoint(member_id, point) {
  return request({
    url: `seller/members/point/${member_id}`,
    method: 'put',
    data: {point}
  })
}

/**
 * 获取会员积分列表
 * @param params
 */
export function getMemberPointList(params) {
  return request({
    url: `seller/members/point/${params.member_id}`,
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取会员积分列表
 * @param params
 */
export function companyGetPageList(params) {
  return request({
    url: `/seller/shops/company/getPageList`,
    method: 'get',
    loading: false,
    params
  })
}


/**
 * 查询一个会员含订单数
 * @param id
 */
export function getmembersgetInfo(id) {
  return request({
    url: `/seller/members/getInfo/${id}`,
    method: 'get',
    loading: false,
  })
}
/**用户详情更新用户余额 */
export function cnpcMemberByMoney(id) {
  return request({
    url: `/seller/members/cnpcMemberByMoney`,
    method: 'get',
    loading: false,
    params: {member_id:id}
  })
}
/**
 * 获取会员代金卡券变动明细
 */
export function getMemberCardChange(params) {
  return request({
    url: '/seller/members/getOrderCard',
    method: 'get',
    params
  })
}
